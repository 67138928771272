
    export default {
     props : ['blok'],
     data() {
         return {
            stories : null,
            alreadyOnRequest : false,
         }
     },
     created() {
         this.getStories();
     },
     mounted() {
         this.getStories();
     },
     methods: {
         getStories(){
             if(!this.stories && !this.alreadyOnRequest){
                this.alreadyOnRequest = true;
                this.$axios.get("/storyblok/stories",{
                    params : {
                        flushcache : 'yes',
                        is_startpage : 'false',
                        starts_with : 'stories'
                    }
                }).then(axRes => {
                    this.stories = axRes.data.stories;
                    this.alreadyOnRequest = false;
                }).catch(axErr => {
                    console.log(axErr.stack);
                    this.alreadyOnRequest = false;
                })
                 
             }
         }
     },
    }
